<template>
    <div class="ui stackable grid m-0">
        <div class="row border-bottom">
            <div class="eight wide left aligned column">
                <h1 class="ui grey header"> Nouvelle Marque </h1>
            </div>
            <div class="eight wide right aligned column">
                <div class="ui primary tiny button" @click="save">
                    <i class="check circle icon"></i>
                    Enregistrer
                </div>
                <div class="ui red button" v-if="isEdit" @click="remove">
                    <i class="times circle icon"></i>
                    Supprimer
                </div>
                <div class="ui button" @click="$router.push({name: 'marqueList'})">
                    <i class="times circle icon"></i>
                    Cancel
                </div>
            </div>
        </div>

        <div class="row">
            <div class="column">
                    <div class="ui form">
                        <div class="ten wide field" :class="{'error': $v.marque.label.$error}" style="margin: auto;">
                            <label>Label</label>
                            <input type="text" placeholder="Label" v-model.trim="$v.marque.label.$model" v-uppercase>
                            <!-- error message -->
                            <div class="ui negative message" v-if="$v.marque.label.$error">
                                <p> <i class="info circle icon"></i> Label est Obligatoire. </p>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    export default {
        name: "marqueCrud",
        props:{
            current_marque: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data(){
          return{
              isEdit: false,
              marque: {
                  label: '',
                  type: 'marque'
              }
          }
        },
        validations: {
            marque: {
                label: {required}
            }
        },
        methods:{
            save(){
                log('save marque:', this.marque);
                this.$v.$touch();
                if (this.$v.$invalid)
                    return;

                let data = {
                    collection: this.$SHARED.collections.vehicule,
                    query: this.marque
                };
                if(this.isEdit){ // update
                    let marque = JSON.parse(JSON.stringify(this.marque));
                    delete marque._id;
                    data.query = {
                        _id: this.marque._id
                    };
                    data.update = {'$set': marque}
                }

                let url = this.isEdit ? this.$SHARED.services.updateIfNotExist
                    : this.$SHARED.services.insertIfNotExist;

                // this.$store[this.isEdit ? 'requestUpdate' : 'requestInsert'](data).then(() => {
                this.$store.dispatchAsync(url, data).then(() => {
                    this.$router.push({name: 'marqueList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.marque[this.isEdit ? 'update_succeeded' : 'create_succeeded']
                    });
                }, code => {
                    let message = this.$SHARED.messages.technical_error;
                    if(code === 7){
                        message = "La marque " + this.$SHARED.messages["7"]
                    }
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: message
                    });
                });
            },
            remove(){
                let data = {
                    collection: this.$SHARED.collections.vehicule,
                    query: {
                        _id: this.marque._id
                    }
                };
                this.$store.requestDelete(data).then(() => {
                    this.$router.push({name: 'marqueList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.marque.delete_succeeded
                    });
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                });
            }
        },
        mounted() {
            if(this.current_marque.hasOwnProperty('_id')){
                this.isEdit = true;
                this.marque = JSON.parse(JSON.stringify(this.current_marque));
            }
        }
    }
</script>

<style scoped>
    .border-bottom{
        border-bottom: 2px solid #767676;
    }
</style>